/** 分享素材 */
export default [
    {
        path: 'sinology/index',
        name: 'sinology-index',
        meta: {
            title: '时间盘',
        },
        component: () => import('@/views/sinology/index.vue')
    },
    {
        path: 'sinology/details',
        name: 'sinology-details',
        meta: {
            title: '时间盘',
        },
        component: () => import('@/views/sinology/details.vue')
    },
    {
        path: 'sinology/record',
        name: 'sinology-record',
        meta: {
            title: '历史记录',
        },
        component: () => import('@/views/sinology/record.vue')
    },
    {
        path: 'sinology/info',
        name: 'sinology-info',
        meta: {
            title: '基本信息',
        },
        component: () => import('@/views/sinology/info.vue')
    },
]