/*时间盘相关
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    getInfoApi: (data) => {//基础信息
        return axios.get('/products/paipan/bazi/base_info', data);
    },
    trueSolarTimeApi: (data) => {// 获取真太阳时
        return axios.get('/products/paipan/bazi/true_solar_time' , data)
    },
    sizhuToDateApi: (data) => {// 四柱转公历
        return axios.get('/products/paipan/bazi/sizhu_to_date' , data)
    },
    queryingApi: (data) => {// 排盘提交
        return axios.post('/products/paipan/bazi/querying' , data)
    },
    getListApi: (data) => {// 历史记录
        return axios.get('/products/paipan/bazi/record/list', data)
    },
    infoApi: (relUid) => {// 历史记录-详情
        return axios.get('/products/paipan/bazi/record/'+relUid+'/info')
    },
    DelListApi: (relUid) => {// 历史记录-详情
        return axios.iDelete('/products/paipan/bazi/record/'+relUid+'/del')
    },
    detailsInfoApi: (relUid) => {// 排盘-详情
        return axios.get('/products/paipan/bazi/detail/'+relUid)
    },
    detailsMoonApi: (data) => {// 排盘-六月信息
        return axios.get('/products/paipan/bazi/querying_yearmonth',data)
    },
    getAddressApi: (data) => {// 地址接口
        return axios.get('/products/get_district',data)
    },
}