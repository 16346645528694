/*智能客服相关
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    customerDetails: (id) => axios.get('/api/api/help_center_question/' + id),//详情
    customerList: (data) => axios.get('/api/api/help_center_question/category/question', data),//相关推荐
    initial: (data) => axios.get('/api/api/help_center_question/v1/index', data),//智能客服初始页
    realTime: (data) => axios.get('/api/api/help_center_question/v1/real-time-search', data),//实时搜索
    search: (data) => axios.get('/api/api/help_center_question/v1/question-search', data),//智能客服搜索
    useful: (data) => axios.post('/api/api/help_center_question/v1/record', data),//问题详情-有(无)用
    problem: (id) => axios.get(`/api/api/help_center_question/v1/details/${id}`),//问题详情
    classification: (id, data) => axios.get(`/api/api/help_center_question/v1/question-list/${id}`, data),//问题分类-问题列表
    getCategory: (data) => axios.get(`/api/api/help_center_question/v1/categories`, data),//分类-二级列
    helpCenterAi: (data) => axios.get(`/api/api/help_center_ai/question-search`, data),//智能Ai客服搜索
    chatHistory: (chat_id,page) => axios.get(`/api/tools/ai/library/chat/history?chat_id=${chat_id}&limit=10&page=${page}`),//历史记录
}