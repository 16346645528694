/*供应商相关
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    partnersAmount: (data) => {//供应商余额
        return axios.get('/passport/partners/amount', data);
    },
    amountList: (data) => {//供应商收益列表
        return axios.get(`/passport/partners/amount-list`, data);
    },
    amountInfo: (data) => {//供应商收益详情
        return axios.get(`/passport/partners/amount-info`, data);
    },
    supplierBank: (data) => {//个人提现获取单个银行卡信息
        return axios.get(`/api/api/withdrawal/supplier/bank`, data);
    },
    withdrawApply: (data) => {//申请提现（个人）
        return axios.tarzantPost(`/account/withdraw/apply`, data);
    },
    withdrawalSet: (type) => {//获取税率
        return axios.get(`/api/api/withdrawal/set?is_person=${type}`);
    },
    companyAuth: () => {//查询用户已成功认证的公司列表
        return axios.get(`/api/tools/fdd/company/my_auth`);
    },
    companyInfo: (id) => {//获取已认证公司信息
        return axios.get(`/api/tools/fdd/company/info?id=${id}`);
    },
    bankAdd: (data) => {//添加公司银行卡信息
        return axios.post(`/api/tools/fdd/company/bank/add`, data);
    },
    selectSave: (data) => {//选择已认证公司，保存接口
        return axios.post(`/api/tools/fdd/company/select/save`, data);
    },
    authUrl: (data) => {//获取新公司实名认证URL
        return axios.post(`/api/tools/fdd/company/auth/url`, data);
    },
    accountApply: (data) => {//供应商对公提现
        return axios.post(`/api/api/withdrawal/account-apply`, data);
    },
    hasWithdrawal: () => {//提现限制
        return axios.get(`/api/api/withdrawal/has_withdrawal/supplier`);
    },
    withdrawCancel: (data) => {//撤销提现
        return axios.post(`/passport/partners/withdraw-cancel`, data);
    },
}