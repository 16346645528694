/*提现相关
i:get || post
h:requestGet ||requestPost
*/
import axios from '../axios'
export default {
    area: (m) => axios.get(`/api/api/area/${m}`),
    way: () => axios.get(`/api/api/withdrawal/way`),//获取用户提现模式
    select: () => axios.get(`/api/api/withdrawal/select`),//获取需要选择实名认证列表
    sole: (data) => axios.post(`/api/api/withdrawal/select`, data),//选择提实名认证
    destroyBank: (data) => axios.post(`/api/api/user_withdrawal/destroyBank`, data),//删除银行卡
    addBank: (data) => axios.post(`/api/api/withdrawal/bank`, data),//个人添加银行卡
    companyAddBank: (data) => axios.post(`/api/api/withdrawal/company-bank`, data),//对公添加银行卡
    getBanks: () => axios.get(`/api/api/user_withdrawal/getBanks`),//获取银行列表
    userBanks: () => axios.get(`/api/api/withdrawal/user-banks`),//添加的的银行卡列表
    single: (bankId = "") => axios.get(`/api/api/withdrawal/single?bank_id=${bankId}`),//获取单个银行卡信息
    calculate: (data) => axios.get(`/api/api/user_withdrawal/calculate`, data),//计算提现实际到账以及应交税
    settlement: (data) => axios.post(`/api/api/withdrawal/settlement`, data),//个人结算
    check: (id_card_id, money) => axios.get(`/api/api/withdrawal/check/${id_card_id}?money=${money}`),//提现验证
    cardName: () => axios.get(`/api/api/withdrawal/id_card`),//获取实名用户名称
    getOrder: (order_sn) => axios.get(`/api/api/user_withdrawal/getOrder?order_sn=${order_sn}`),//根据订单号获取提现订单
    companyBank: (returnUrl = "") => axios.get(`/api/api/withdrawal/company-bank?return_url=${returnUrl}`),//获取对公提现信息
    companySettlement: (data) => axios.post(`/api/api/withdrawal/company-settlement`, data),//对公结算
    settlementDetail: (id) => axios.get(`/api/api/account/detail/info?id=${id}`),//结算明细详情
    sign:() => axios.tarzantGet(`/account/withdraw/getContract`),//结算明细详情
    signPos:() => axios.tarzantPost(`/account/withdraw/sign`),//结算明细详情
    evidence:(type) => axios.get(`/api/api/evidence/${type}`),//获取材料
}