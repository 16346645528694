import store from '../../store/index'
import publicGetShareInfo from '@/common/share/between'
import VueCookies from 'vue-cookies'//vue-cookies插件
export async function getInformation(to, next, cookToken) {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    // let sessionUserInfo = sessionStorage.getItem('userInfo');
    let cookiesMechanismInfo = VueCookies.get('cookiesMechanismInfo');
    // if (sessionUserInfo && JSON.parse(sessionUserInfo).token == cookToken) {
    //     store.commit('loginInfo/userInfo', JSON.parse(sessionUserInfo))
    //     store.commit('loginInfo/balance', JSON.parse(sessionUserInfo).amount)
    // } else {
    //     await store.dispatch('loginInfo/getUser', cookToken) //获取用户信息
    // }
    if (cookiesMechanismInfo) {
        store.commit('loginInfo/mechanismInfo', cookiesMechanismInfo)
    } else {
        await store.dispatch('loginInfo/setMechanism') //获取机构信息
    }
    if (!store.state.loginInfo.userInfo) {
        await store.dispatch('loginInfo/getUser', cookToken) //获取用户信息
    }
    // if (!store.state.loginInfo.mechanismInfo) {
    //     await store.dispatch('loginInfo/setMechanism') //获取机构信息
    // }
    // if (store.state.agent.agentVersion.agent_equity_style === '') {
    //     await store.dispatch('agent/setAgentVersion');
    // }
    await publicGetShareInfo(to)//调用微信分享接口
    if (cookiesMechanismInfo && store.state.loginInfo.jssdkToken != cookiesMechanismInfo.mechanism_token) {
        await store.dispatch('loginInfo/setMechanism') //获取机构信息
    }
    next()
}
