/* 兼职服务相关 */
/* keepAlive:该页面是否缓存 */
export default [
    {
        path: 'partTime/incomeDetail',
        name: 'incomeDetail',
        meta: {
            title: '供应商收益',
        },
        component: () => import('@/views/partTime/incomeDetail.vue')
    },
    // {
    //     path: 'partTime/orderList',
    //     name: 'orderList',
    //     meta: {
    //         title: '订单明细',
    //     },
    //     component: () => import('@/views/partTime/orderList.vue')
    // },
    // {
    //     path: 'partTime/myPart',
    //     name: 'myPart',
    //     meta: {
    //         title: '我的兼职',
    //     },
    //     component: () => import('@/views/partTime/myPart.vue')
    // },
    {
        path: 'partTime/orderDetail',
        name: 'orderDetail',
        meta: {
            title: '详情',
        },
        component: () => import('@/views/partTime/orderDetail.vue')
    },
    {
        path: 'partTime/enterprise',
        name: 'enterprise',
        meta: {
            title: '选择已认证企业',
        },
        component: () => import('@/views/partTime/enterprise.vue')
    },
    {
        path: 'partTime/companyCertification',
        name: 'companyCertification',
        meta: {
            title: '公司提现',
        },
        component: () => import('@/views/partTime/companyCertification.vue')
    },
    {
        path: 'partTime/maleWithdrawal',
        name: 'maleWithdrawal',
        meta: {
            title: '公司提现',
        },
        component: () => import('@/views/partTime/maleWithdrawal.vue')
    },
    // {
    //     path: 'partTime/courseInstructor',
    //     name: 'courseInstructor',
    //     meta: {
    //         title: '课程讲师',
    //     },
    //     component: () => import('@/views/partTime/courseInstructor.vue')
    // },
    {
        path: 'partTime/personageCertification',
        name: 'personageCertification',
        meta: {
            title: '个人提现',
        },
        component: () => import('@/views/partTime/personageCertification.vue')
    },
    {
        path: 'partTime/addCard',
        name: 'addCard',
        meta: {
            title: '绑定银行卡',
        },
        component: () => import('@/views/partTime/addCard.vue')
    },
    {
        path: 'partTime/personalWithdrawal',
        name: 'personalWithdrawal',
        meta: {
            title: '个人提现',
        },
        component: () => import('@/views/partTime/personalWithdrawal.vue')
    },
    {
        path: 'partTime/cardList',
        name: 'cardList',
        meta: {
            title: '银行卡',
        },
        component: () => import('@/views/partTime/cardList.vue')
    },
    // 个人提现成功
    {
        path: 'partTime/personalSuccess',
        name: 'personalSuccess',
        meta: {
            title: '结算',
        },
        component: () => import('@/views/partTime/personalSuccess.vue')
    },
     // 公司提现成功
     {
        path: 'partTime/maleSuccess',
        name: 'maleSuccess',
        meta: {
            title: '结算',
        },
        component: () => import('@/views/partTime/maleSuccess.vue')
    },
]
