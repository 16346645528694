
import { api_common } from "@/api/interface/index";//分享api
// afterSharingApi
import wx from 'weixin-js-sdk';
import { Toast } from 'vant';
import store from '@/store/index'
import authorization from '@/common/get-wechat/authorization.js';
import VueCookies from 'vue-cookies'
/**
 *分享
 * @param shareTitle 标题
 * @param shareUrl 链接
 * @param shareImg 图片
 * @param shareDesc 描述
 */
export const commonShare = async (shareTitle, shareUrl, shareImg, shareDesc) => {
    let url = window.location.href;
    let apis = [
        'updateAppMessageShareData',
        'updateTimelineShareData',
        'startRecord', // 录音开始api
        'stopRecord', // 录音结束api
        'onVoiceRecordEnd', // 超过一分钟自动停止api
        'playVoice', // 播放录音api
        'pauseVoice', // 暂停录音api
        'stopVoice',    // 停止播放apiplayVoice
        'onVoicePlayEnd', // 监听语音播放完毕api
        'downloadVoice',
        'uploadVoice',
        'chooseImage',
        'previewImage',
        'uploadImage',
        'downloadImage',
        'getLocalImgData',
        'hideMenuItems',
        'chooseWXPay',
        'getNetworkType',
        'checkJsApi',
        'scanQRCode',
    ]
    // let sdk = sessionStorage.getItem('js_sdk');
    // if (sdk) {
    //     let obj = JSON.parse(sdk)
    //     wx.config({
    //         // res.debug
    //         debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    //         appId: obj.appId, // 必填，公众号的唯一标识
    //         timestamp: obj.timestamp, // 必填，生成签名的时间戳
    //         nonceStr: obj.nonceStr, // 必填，生成签名的随机串
    //         signature: obj.signature, // 必填，签名，见附录1
    //         jsApiList: obj.jsApiList// 必填，需要使用的JS接口列表，所有JS接口列表见附录2
    //     });
    // } else {
    //     await api_common.shareApi({ url, apis }).then(res => {
    //         sessionStorage.setItem('js_sdk', JSON.stringify(res))
    //         wx.config({
    //             // res.debug
    //             debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    //             appId: res.appId, // 必填，公众号的唯一标识
    //             timestamp: res.timestamp, // 必填，生成签名的时间戳
    //             nonceStr: res.nonceStr, // 必填，生成签名的随机串
    //             signature: res.signature, // 必填，签名，见附录1
    //             jsApiList: res.jsApiList// 必填，需要使用的JS接口列表，所有JS接口列表见附录2
    //         })
    //     })
    // }
    await api_common.shareApi({
        jssdk: {
            url: url,
            apis: apis
        },
        request_log: {
            url: window.location.href,
            title: document.title,
            share_id: store.state.share.shareId,
        }
    }).then(res => {
        if (res.code == 200) {
            store.commit('loginInfo/jssdkToken', res.data.mechanism_token)
            let jssdk = res.data.jssdk;
            if (jssdk.is_login == 1) {
                const cookToken = VueCookies.get('token');//Cookies中token
                if (cookToken) {
                    VueCookies.remove("token");
                    authorization()
                }
            } else {
                wx.config({
                    // res.debug
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: jssdk.appId, // 必填，公众号的唯一标识
                    timestamp: jssdk.timestamp, // 必填，生成签名的时间戳
                    nonceStr: jssdk.nonceStr, // 必填，生成签名的随机串
                    signature: jssdk.signature, // 必填，签名，见附录1
                    jsApiList: jssdk.jsApiList// 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                })
                // let mechanismId = store.state.loginInfo.mechanismInfo.mechanism_id
                wx.ready(function () {
                    //分享到朋友圈
                    wx.updateTimelineShareData({
                        title: shareTitle, // 分享标题
                        link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: shareImg, // 分享图标
                        success: function () {
                            // 用户确认分享后执行的回调函数
                            // api_common.afterSharingApi(mechanismId, { type: 'globalShare' })
                        },
                        cancel: function () {
                            // 用户取消分享后执行的回调函数
                            Toast('取消分享');
                        }
                    });
                    //分享到朋友
                    wx.updateAppMessageShareData({
                        title: shareTitle, // 分享标题
                        desc: shareDesc, // 分享描述
                        link: shareUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                        imgUrl: shareImg, // 分享图标
                        // type: "", // 分享类型,music、video或link，不填默认为link
                        // dataUrl: "", // 如果type是music或video，则要提供数据链接，默认为空
                        success: function () {
                            // 用户确认分享后执行的回调函数
                            // afterSharingApi({ type: 'globalShare' })
                            // api_common.afterSharingApi(mechanismId, { type: 'globalShare' })
                        },
                        cancel: function () {
                            // 用户取消分享后执行的回调函数
                            Toast('取消分享');
                        }
                    });
                    wx.hideMenuItems({
                        menuList: [
                            'menuItem:copyUrl'
                        ]
                    });
                });
            }
        }
    })
};