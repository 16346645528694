
import * as types from '../types.js'
import { api_user, api_common } from '@/api/interface';
import storage from '@/common/uilt/storage'
import VueCookies from 'vue-cookies'//vue-cookies插件
import publicGetShareInfo from '@/common/share/between'
import { Toast } from 'vant';
import wechatParm from "@/api/config";
import { Base64 } from "js-base64";
const loginInfo = {
    namespaced: true,
    state: {
        mechanismInfo: null,//机构信息
        userInfo: null,//用户信息
        balance: null,//用户余额
        jumpUrl: 'http://wx6a600e869db04393.m.xueliangzhaoedu.cn/',
        followInfo: { isFollow: true, codeLink: '' },//公众号关注信息：isFollow true已关注false未关注 codeLink二维码链接
        commentAuthority: true,//评论权限
        jssdkToken:'',//分享接口的机构token
    },
    mutations: {
        [types.MECHANISM_INFO](state, mechanismInfo) {
            state.mechanismInfo = mechanismInfo;
        },
        [types.USER_INFO](state, userInfo) {
            state.userInfo = userInfo;
        },
        [types.BALANCE](state, num) {//用户余额
            state.balance = Number(num);
        },
        [types.FOLLOW_INFO](state, followInfo) {//关注公众号信息
            state.followInfo = followInfo;
        },
        [types.COMMENT_AUTHORITY](state, commentAuthority) {//评论权限
            state.commentAuthority = commentAuthority;
        },
        jssdkToken(state,jssdkToken){
            state.jssdkToken = jssdkToken;
        }
    },
    getters: {
        mechanismInfo: state => state.mechanismInfo,
        userInfo: state => state.userInfo,
        balance: state => state.balance,
        jumpUrl: state => state.jumpUrl,
        followInfo: state => state.followInfo,
        commentAuthority: state => state.commentAuthority,//评论权限
        jssdkToken: state => state.jssdkToken,
    },
    actions: {
        setMechanism: async ({ commit }) => {
            await api_common.mechanismInfoApi().then(res => { //获取机构信息
                if (res.code == 200) {
                    commit(types.MECHANISM_INFO, res.data);
                    VueCookies.set("cookiesMechanismInfo", JSON.stringify(res.data), "2d");
                }
            })
        },
        setUser: async ({ commit }, obj) => {//获取用户信息
            await api_user.wxLoginApi({
                code: obj.code,
                inviteUid: obj.inviteUid
            }).then(async res => {
                if (res.code == 200) {
                    if (res.data.uid != '') {
                        commit(types.USER_INFO, res.data)
                        commit(types.BALANCE, res.data.amount)
                        sessionStorage.setItem('userInfo', JSON.stringify(res.data));
                        storage.setItem({
                            name: "token",
                            value: res.data.token,
                            expires: "30d",
                        });
                        VueCookies.set("token", res.data.token, "1m");
                        await publicGetShareInfo(obj.to)//调用微信分享接口
                        if (obj.to.meta.title) {
                            document.title = obj.to.meta.title;
                        }
                        obj.next()
                    } else {
                        window.location.href =
                            obj.domain +
                            "?renUrl=" +
                            encodeURIComponent(obj.backUrl)
                            +
                            "&mechanismOpenId=" +
                            res.data.openid;
                    }
                } else {
                    alert(res.msg)
                }
            })
        },
        accreditGetToken: async ({ commit }, obj) => {
            await api_user.accredit({ nonce: obj.nonce, code: obj.code, share_uid: obj.share_uid }).then(async res => {//机构授权获取token
                if (res.code == 200) {
                    VueCookies.set("token", res.data.token, "1m");
                    window.location.replace(Base64.decode(obj.backUrl));//请求成功去第一次进入的页面
                } else {//请求失败去登录页面
                    Toast('登录失败，请重新登录');
                    let replaceUrl = `/page/login/index?nonce=${obj.nonce}&share_uid=${obj.share_uid}&return_url=${obj.backUrl}`//去登录页面
                    window.location.replace(replaceUrl)
                }
            }).catch(() => {
                Toast('登录失败，请重新登录');
                let replaceUrl = `/page/login/index?nonce=${obj.nonce}&share_uid=${obj.share_uid}&return_url=${obj.backUrl}`//去登录页面
                window.location.replace(replaceUrl)
            })

        },
        getUser: async ({ commit }, cookToken) => {
            await api_user.userApi({ token: cookToken }).then(res => {//通过token或者token不一样请求接口获取用户信息
                if (res.code == 200) {
                    commit(types.USER_INFO, res.data)
                    commit(types.BALANCE, res.data.amount)
                    sessionStorage.setItem('userInfo', JSON.stringify(res.data));
                    storage.setItem({
                        name: 'token',
                        value: res.data.token,
                        expires: '30d'
                    })
                    VueCookies.remove(
                        "token",
                        "/",
                        wechatParm.levelDomain
                    );
                    VueCookies.set("token", res.data.token, "1m");
                } else {
                    Toast(res.msg)
                }
            })
        },
        setBalance: async ({ commit }) => {//获取用余额
            await api_user.balanceApi().then(res => {
                if (res.code == 200) {
                    commit(types.BALANCE, res.data.amount)
                }
            })
        },
        getCommentAuthority: async ({ commit }) => {//获取评论权限
            await api_common.authority('comment').then(res => {
                if (res.code == 200) {
                    if (res.data.access != 1) {
                        commit(types.COMMENT_AUTHORITY, false)
                    }
                }
            })
        },
    }
}

export default loginInfo
