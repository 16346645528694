<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {};
  },
  methods: {
    autoFontSize() {
      if (
        typeof WeixinJSBridge == "object" &&
        typeof WeixinJSBridge.invoke == "function"
      ) {
        //判断程序运行环境是否是微信浏览器(微信内置的浏览器)
        this.handleFontSize();
      } else {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            this.handleFontSize,
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", this.handleFontSize);
          document.attachEvent("onWeixinJSBridgeReady", this.handleFontSize);
        }
      }
    },
    handleFontSize() {
      // 设置网页字体为默认大小
      WeixinJSBridge.invoke("setFontSizeCallback", {
        fontSize: 0
      });
      // 重写设置网页字体大小的事件
      WeixinJSBridge.on("menu:setfont", () => {
        WeixinJSBridge.invoke("setFontSizeCallback", {
          fontSize: 0
        });
      });
    }
  },
  mounted() {
    let _this = this;
    _this.autoFontSize();
    window.addEventListener("resize", () => {
      _this.autoFontSize();
    });
    window.onload = function() {
      _this.autoFontSize();
      window.addEventListener("resize", () => {
        _this.autoFontSize();
      });
    };
  }
};
// vue页面滚动和textarea滚动冲突问题 解决所有界面中textarea不滚动问题
window.addEventListener(
  "touchmove",
  function(e) {
    var target = e.target;
    if (target && target.tagName === "TEXTAREA") {
      //textarea阻止冒泡
      e.stopPropagation();
    }
  },
  true
);
</script>
<style>
.van-image-preview__overlay {
  background-color: rgba(0, 0, 0, 0.4) !important;
}
</style>