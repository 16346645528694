import axios from '../axios'

export default {
    // shareApi: (data) => {// 获取wx config信息
    //     return axios.post('/api/api/wechat/jssdk', data);
    // },
    shareApi: (data) => {// 获取wx config信息
        return axios.post('/api/tools/base/info', data);
    },
    mechanismInfoApi: () => {// 获取机构的基本信息
        return axios.get('/api/api/mechanism/info')
    },
    afterSharingApi: (mechanismId, data) => {// 分享后调用的方法
        return axios.post('/api/api/integral/earn/' + mechanismId, data)
    },
    shareOneApi: (data) => {// 获取页面title和分享人id 如果不是默认传参的话，请在路由meta中添加isDefault属性为true，然后在相应页面中调用该方法
        return axios.post('/api/api/request/log', data)
    },
    taskInfoApi: (data) => {// 打卡跳转用，获取作业详情
        return axios.get('/api/api/task/info/' + data.id, data);
    },
    sevenNiuYunTokenApi: () => {//获取七牛token img
        return axios.requestGet('/v1/ucentor/uploads/upload-type/2')
    },
    getQnToken3: () => {//七牛上传视频token
        return axios.requestGet('/v1/ucentor/uploads/upload-type/3');
    },
    focusApi: () => {//判断是否关注公众号
        return axios.get('/api/api/home/follow/wechat')
    },
    getAreaList: (data) => {// 省市区接口
        return axios.get('/api/api/area/all', data)
    },
    getShareInfo: (type, data) => {// 分享接口 type: 2-约见详情
        return axios.get(`/api/api/mechanism/share_info/${type}`, data)
    },
    authority: (type, goodsId = "", goodsType = "", mobile = "") => axios.get(`/api/api/user_access?type=${type}&goods_id=${goodsId}&goods_type=${goodsType}&mobile=${mobile}`),//获取权限
    getQnToken1: () => {//七牛上传音频token
        return axios.requestGet('/v1/ucentor/uploads/upload-type/1');
    },
    getQnToken7: () => {//七牛上传文件token
        return axios.requestGet('/v1/ucentor/uploads/upload-type/7');
    },
    getQnTokenNew: (data) => {//七牛上传文件token 最新版 所有都走这个
        return axios.get('/api/tools/files/qiniu-token',data);
    },
    addMaterial: (data) => {//七牛上传文件token 最新版 所有都走这个
        return axios.post('/api/tools/files/add',data);
    },
}