/*打卡相关
i:get || post
h:requestGet ||requestPost
*/
import { data } from 'browserslist';
import axios from '../axios'
export default {
    wxVoiceUpload: (mechanismId, data) => {//微信录音上传到服务器
        return axios.requestPost('/v1/uploads/amr/from/wx/' + mechanismId, data);
    },
    getTrainClass: () => {
        return axios.get('/products/camp/category');
    },
    getTrainList: (data) => {
        return axios.get('/products/camp/list?catid=' + data.tagId + '&page=' + data.page);
    },
    getBaseData: (data) => { //获取详情基本信息
        return axios.get('/products/camp/info', data);
    },
    getClassList: (data) => { //小灶课
        return axios.get('/products/camp/get_special_list', data)
    },
    getWorksList: (id, data) => { //课表
        return axios.get(`/products/camp/${id}/dates`, data)
    },
    getWorksDetail: (data) => { //作业
        return axios.get('products/camp/get_task_content', data)
    },
    getLessonList: (id, data) => { //小灶课
        return axios.get('products/camp/user/special_mess_list?camp_id=' + id, data)
    },
    subLessonList: (data) => { //提交小灶课播放时长
        return axios.post('products/camp/user/special_mess_schedule', data)
    },
    getIntegral: (mechanismId, price) => { //获取积分
        return axios.get(`api/api/integral/deduction/${mechanismId}?true_price=${price}`)
    },
    getCoupon: (mechanismId, id) => { //获取优惠券
        return axios.requestGet(`v1/ucentor/coupons/usable/28/mechanism_id/${mechanismId}/${id}`)
    },
    teacherInfo: (campId, data) => axios.get(`/products/camp/${campId}/teachers/info`, data),//导师信息

    // otherTeacherInfo: (campId,tutorId) => axios.get(`/products/camp/${campId}/teachers/info?tutor_id=${tutorId}`),//导师详情-其他导师信息
    otherTeacherInfo: (classId, tutorId) => axios.get(`/products/camp/classes/${classId}/teachers/${tutorId}`),//导师详情-其他导师信息
    responsibleClass: (campId, data) => axios.get(`/products/camp/${campId}/teachers/classes`, data),//负责的班级
    studentsPoints: (classId, tutorId) => axios.get(`/products/camp/classes/${classId}/allot-teacher-categories?tutor_id=${tutorId}`),//分配导师-学员列表分类
    distributionStudentsList: (classId, data) => axios.get(`/products/camp/classes/${classId}/allot-teacher-users`, data),//分配导师-学员列表
    allInstructors: (classId, userId) => axios.get(`/products/camp/classes/${classId}/allot-teachers?user_id=${userId}`),//分配导师-班级所有导师
    searchStudents: (classId, tutorId, search) => axios.get(`/products/camp/classes/${classId}/users?tutor_id=${tutorId}&search=${search}`),//搜索学员
    pointsMentor: (campId, data) => axios.post(`/products/camp/${campId}/teacher-allots`, data),//给学员分配导师
    studentTeacher: (campId, userId, data) => axios.get(`/products/camp/${campId}/users/${userId}/teachers`, data),//学员的所有导师
    removeTeacher: (campId, data) => axios.iDelete(`/products/camp/${campId}/teacher-allots`, data),//移除导师
    campClassification: () => axios.get(`/products/camp/center/teachers/camp-categories`),//训练营分类列表
    responsibleCamp: (started, page) => axios.get(`/products/camp/center/teachers/camps?type=${started}&page=${page}`),//我负责的训练营
    instituteClassification: (classId, taskId) => axios.get(`/products/camp/center/teachers/classes/${classId}/tasks/${taskId}/users-categories`),//学员管理-分类
    studentsList: (classId, taskId, data) => axios.get(`products/camp/center/teachers/classes/${classId}/tasks/${taskId}/users`,data),//学员管理-学员列表 
    assessment: (classId, taskId, sort) => axios.get(`/products/camp/center/teachers/classes/${classId}/tutor-assess?task_id=${taskId}&sort=${sort}`),//导师考核-每日/累计考核
    userClassification: (classId, tutorId, taskId) => axios.get(`/products/camp/classes/${classId}/teachers/${tutorId}/tasks/${taskId}/users-categories`),//导师详情-用户分类
    // detailsStudentsList: (classId, tutorId, taskId, appraise, page) => axios.get(`/products/camp/classes/${classId}/teachers/${tutorId}/tasks/${taskId}/users?type=${appraise}&page=${page}`),//导师详情-学员列表 见reviewList 同一个接口
    studentInformation: (userId) => axios.get(`/products/camp/users/${userId}/member-info`), // 详细数据-学员信息
    jobInformation: (userId, taskId) => axios.get(`/products/camp/users/${userId}/tasks/${taskId}`), // 详细数据-作业信息
    mentorCalendar: (classId, type) => axios.get(`/products/camp/center/teachers/classes/${classId}/dates?type=${type}`),//班级管理日历
    mentorDetailsCalendar: (classId, tutorId) => axios.get(`/products/camp/classes/${classId}/teachers/${tutorId}/dates`),//导师详情日历
    clockNotice: (data) => axios.post(`/products/camp/center/teachers/classes/un-task-notices`, data),//导师考核-有未打卡学员通知
    reviewNotice: (data) => axios.post(`/products/camp/center/teachers/classes/un-appraise-notices`, data),//导师考核-有未点评作业通知
    reviewList: (classId, tutorId, taskId, data) => axios.get(`/products/camp/classes/${classId}/teachers/${tutorId}/tasks/${taskId}/users`, data),//点评学员-学员列表  导师详情-学员列表
    reviewClassification: (classId, tutorId, taskId) => axios.get(`/products/camp/classes/${classId}/teachers/${tutorId}/tasks/${taskId}/users-categories`),//点评学员-用户分类
    separateClockNotice: (data) => axios.post(`/products/camp/users/un-task-notice`, data),//点评学员-学员未打卡提醒
    reviewCalendar: (classId, tutorId) => axios.get(`/products/camp/classes/${classId}/teachers/${tutorId}/dates`),//点评学员-日历
    campCoupons: (mechanismId, campId) => axios.requestGet(`/v1/ucentor/coupons/usable/38/mechanism_id/${mechanismId}/${campId}`),//训练营优惠券




    getFormInfo: (data) => axios.get(`/products/camp/get_enter_info`, data),//训练营-前台 获取报名表单信息
    submitFormInfo: (data) => axios.post(`/products/camp/submit_form`, data),//训练营-前台 提交报名表单信息
    campUserInfo: (data) => axios.get(`/products/camp/user/get_sign_info`, data),//打卡-用户端 训练营相关信息
    campRule: (data) => axios.get(`/products/camp/user/get_sign_rule`, data),//打卡-用户端 查看打卡规则
    campWorkCalendar: (campId, data) => axios.get(`/products/camp/${campId}/user-dates`, data),//打卡-用户端 学员打卡日历
    campWorkInfo: (campId, data) => axios.get(`/products/camp/${campId}/task-info`, data),//打卡-用户端 学员打卡聚合数据 
    taskStatus: (taskId) => axios.get(`/products/camp/user/sign_task_status/${taskId}`),//打卡-用户端 获取提交打卡状态
    campDayWorkCon: (data) => axios.get('/products/camp/user/get_sign_content', data),//打卡-用户端 本日内容 
    taskSchedule: (data) => axios.post('/products/camp/user/task_schedule', data),//打卡-用户端 更新作业学习进度 
    taskList: (data) => axios.get('/products/camp/user/sign_list', data),//打卡-用户端 用户本日作业列表 
    praiseList: (data, source) => axios.get('/products/camp/user/praise_list/' + data + '/' + source),//打卡-用户端 点赞列表 
    commentList: (data, source) => axios.get('/products/camp/user/comment_list/' + data + '/' + source),//打卡-用户端 评论列表 
    postCampOrder: (data) => axios.post('/products/camp/payment', data),//训练营-前台 训练营下单 
    getStudentTaskInfo: (data) => axios.get('/products/camp/user/sign_task_info', data),//打卡-用户端 查询打卡信息
    getDraftList: (data) => axios.get('/products/camp/user/sign_draft_list', data),//打卡-用户端 查询作业草稿 
    deleteDraft: (data) => axios.iDelete('/products/camp/user/sign_draft_del', data),//打卡-用户端 删除作业草稿 
    editDraft: (data) => axios.post('/products/camp/user/sign_draft', data),//打卡-用户端 保存草稿
    // getStudentTaskCon: (data) => axios.get('/products/camp/user/sign_task_show', data),//打卡-用户端 查询作业内容 2022.7.14弃用了，改用getStudentTaskDetail
    editStudentTask: (data) => axios.post('/products/camp/user/sign_task', data),//打卡-用户端 提交作业内容
    reviseStudentTask: (data) => axios.post('/products/camp/user/sign_task_edit', data),//打卡-用户端 修改作业内容
    shareTask: (studentTaskId) => axios.post('/products/camp/user/sign_task_share/' + studentTaskId),//打卡-用户端 分享操作
    excellentTask: (studentTaskId, data) => axios.post('/products/camp/user-tasks/' + studentTaskId + '/excellent', data),//打卡-用户端 评优质操作
    praiseTask: (data) => axios.post('/products/camp/user/sign_task_praise', data),//打卡-用户端 点赞操作
    commentTask: (data) => axios.post('/products/camp/user/sign_task_comment', data),//打卡-用户端 评论操作
    deleteComment: (commentId) => axios.iDelete('/products/camp/user/comment_del/' + commentId),//打卡-用户端 删除评论操作
    getStudentTaskDetail: (data) => axios.get('/products/camp/user/sign_detail', data),//打卡-用户端 作业详情页
    getAppraiseRules: (campId) => axios.get('/products/camp/' + campId + '/appraise-rules'),//我是导师 点评学员-达标规则
    appraiseTask: (studentTaskId, data) => axios.post('/products/camp/user-tasks/' + studentTaskId + '/appraise', data),//我是导师 提交作业点评学员
    signInfo: (campId, data) => axios.get('/products/camp/' + campId + '/user/sign_info', data),//打卡记录 用户打卡信息
    signList: (campId,stageId, data) => axios.get('/products/camp/' + campId + '/stage/' + stageId + '/user_sign_list', data),//打卡记录 用户打卡记录
    certificateList: (campId, data) => axios.get('/products/camp/' + campId + '/cert_record', data),//证书-前台 获取证书列表 
    recordInfo: (certificateId, data) => axios.get('/products/camp/cert_detail/' + certificateId, data),//证书-前台 获取证书详情 
    getInputVideo: () => axios.get('/products/camp/input_video'),//输入法视频



    classInformation: (campId, userId, data) => axios.get(`/products/camp/${campId}/users/${userId}/class`, data),//我的班级-班级信息
    rankingList: (classId, data) => axios.get(`/products/camp/classes/${classId}/rankings`, data),//我的班级-排行榜
    returnsSummary: (campId, data) => axios.get(`/products/camp/${campId}/teachers/earnings/appraises`, data),//点评收益-点评收益汇总
    earningsRecord: (campId, data) => axios.get(`/products/camp/${campId}/teachers/earnings/appraise-records`, data),//点评收益-点评收益记录
    integralSubsidiary: (campId, data) => axios.get(`/products/camp/${campId}/score_detail`, data),//用户积分明细
    IntegralRules: (campId, data) => axios.get(`/products/camp/${campId}/score_rule`, data),//用户积分规则
    courseCampPoints: (userId) => axios.get(`/api/api/center/courses/camps/categories?user_id=${userId}`),//我的课程-训练营-分类
    courseCamp: (data) => axios.get(`/api/api/center/courses/camps`, data),//我的课程-训练营列表
    rankingScope: (campId, data) => axios.get(`/products/camp/${campId}/rankings`, data),//排行榜
    // postersList: (campId) => axios.get(`/products/camp/${campId}/poster_list`),//分享训练营-海报列表
    postersList: (campId) => axios.get(`/products/poster?type=products&product_type=camp&sub_product_id=${campId}`),//分享训练营-海报列表
    posterSignList: (campId, data) => axios.get(`/products/camp/${campId}/poster_sign_list`, data),//分享日签图-海报列表
    copywritingList: (campId) => axios.get(`/products/camp/${campId}/copywriting_list`),//分享训练营-文案列表
    campContent: (campId) => axios.get(`/products/camp/${campId}/camp_content`),//分享训练营-训练营简介
    shareNews: (campId) => axios.get(`/products/camp/${campId}/share_news`),//分享训练营-软文
    campQuotes: () => axios.get(`/api/api/carding/word`),//分享日签图-获取金句
    configuration: (campId, data) => axios.get(`/products/camp/${campId}/poster_sign_config`, data),//分享日签图-配置数据
    invitationRecord: (campId, userId, data) => axios.get(`/products/camp/${campId}/users/${userId}/invites`, data),//邀请记录
    conversionEarnings: (campId, data) => axios.get(`/products/camp/${campId}/teachers/earnings/commissions`, data),//转化收益-转化收益聚合数据 
    conversionRecord: (commissionId, page) => axios.get(`/products/camp/teachers/earnings/commissions/${commissionId}/records?page=${page}`),//转化收益-转化记录 
    conversionRules: (commissionId) => axios.get(`/products/camp/teachers/earnings/commissions/${commissionId}/rules`),//转化收益-转化规则
    studentsExport: (campId, data) => axios.post(`/products/camp/${campId}/exports/classes/manage-users`, data),//学员管理-班级学员每日数打卡据导出
    clockExport: (campId, data) => axios.post(`/products/camp/${campId}/exports/teachers/manage-users`, data),//导师详情-学员数据导出
    mentorExport: (campId, data) => axios.post(`/products/camp/${campId}/exports/teachers/assess`, data),//导师考核-数据导出
    identityStatus: () => axios.get(`/api/api/identity_status`),// 判断训练营还是约见



    // 增加期数
    getStageList: (campId, data) => axios.get(`/products/camp/${campId}/get_stage_list`,data),// 获取训练营可选择的期数
    getBuyStage: (campId) => axios.get(`/products/camp/${campId}/get_user_stage`),// 获取已购买过的期数
    getGiveProduct: (campId,data) => axios.get(`/products/type/camp/id/${campId}/get_give_product`,data),// 获取已购产品抵扣
    getContact: (campId) => axios.get(`/products/camp/${campId}/contact_info`),// 获取联系客服信息

    getMaterialInfo: (id,data) => axios.get(`/products/camp/material_info/${id}`,data),// 获取联系客服信息

    // 闯关单独接口
    getTaskList: (campId, data) => axios.get(`/products/camp/${campId}/user/get_sign_list`,data),// 获取用户打卡记录
    getBarrierList: (data) => axios.get(`/products/camp/barrier_list`,data),//获取关卡
    lockNotice: (data) => axios.post(`/products/camp/center/teachers/classes/lock-notices`, data),//导师考核-有未解锁通知

    // 训练营勋章
    contentMedalList: (campId,stageId) => axios.get(`/products/camp/${campId}/stage/${stageId}/medals`),//训练营内容页勋章列表
    recordMedalList: (campId,stageId,data) => axios.get(`/products/camp/${campId}/stage/${stageId}/awarded_medals`,data),//打卡记录-勋章成就列表
    recordMedalDetails: (campId,stageId,data) => axios.get(`/products/camp/${campId}/stage/${stageId}/medal/detail`,data),//勋章详情
    medalPop: (campId,stageId,data) => axios.get(`/products/camp/${campId}/stage/${stageId}/medal/pop_detail`,data),//弹窗勋章详情


    // 公共评论
    getCommonDataInfo: (type, id) => axios.get(`/products/products/header_info/${type}/${id}`),// 数据详情
    getCampCommentList: (data) => axios.get(`/api/tools/comment/list`,data),// 评价列表
    campAddComment: (data) => axios.post(`/api/tools/comment/add`, data), //发表评价
    campUpComment: (data) => axios.post(`/api/tools/comment/update`, data), //编辑评价
    campDelComment: (data) => axios.post(`/api/tools/comment/del`, data), //删除评价
    getCampCommentInfo: (data) => axios.get(`/api/tools/comment/info`,data), //评价详情
    campLoveAddComment: (data) => axios.post(`/api/tools/comment/love/add`, data), //点赞评价
    campLoveCancelComment: (data) => axios.post(`/api/tools/comment/love/cancel`, data), //取消点赞
    campReplyAddComment: (data) => axios.post(`/api/tools/comment/reply/add`, data), //回复评论
    campReplyDelComment: (data) => axios.post(`/api/tools/comment/reply/del`, data), //删除回复的评论
    getTag: () => axios.get(`/api/api/mechanism/tag/box`), //获取标签
    setUserTag: (data) => axios.post(`/api/api/mechanism/tag/set-user-tag`, data), //导师贴标签
}