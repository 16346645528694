/*数字单位转换*/
/*
* @param {num} num 输入数字. 
* @param {number} dec 小数点后最多位数，默认为2 (传2 && processType == 'price'保留两位小数点)
* @param {string} symbol  默认为w  传chinese 中文单位 万 
*/
import Decimal from 'decimal.js';
export function formatNumber(num, dec, symbol, processType) {
    var malDigit = dec || dec == 0 ? dec : 2;
    var multiple = 1;
    let result = 0;
    for (let i = 0; i < malDigit; i++) {
        multiple *= 10
    }
    num = Number(num);
    if (num == 0) {
        return num + '';
    } else {
      // 为避免精度丢失 使用Decimal进行计算 之前计算19.99会转成19.98
      // plus 加 minus 减 times 乘  div 除
      if (num >= 0 && num < 10000) {    
          let decimalNum = new Decimal(num).times(new Decimal(multiple));
          result = decimalNum.floor().div(multiple).toNumber();
          if(dec && dec == 2 && processType == 'price'){
              result = result.toFixed(2)
          }    
          return result;
        } else if (num >= 10000 && num < 100000000) {
        // var car = num / 10000;
            var car = new Decimal(num).div(10000);
            let symbolFont = symbol == 'chinese' ? '万' : 'w';
            let decimalNum = car.times(new Decimal(multiple))
            result = decimalNum.floor().div(multiple).toNumber();
            return result + symbolFont;
      } else {
            let divNum = new Decimal(num).div(100000000);
            let decimalNum = divNum.times(new Decimal(multiple));
            result = decimalNum.floor().div(multiple).toNumber();
            return result + '亿';
        }
    }
}
